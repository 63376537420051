import { render, staticRenderFns } from "./index.vue?vue&type=template&id=44f6d023"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductsCategoriesSlider: require('/src/components/products/CategoriesSlider.vue').default,FilterSearchWithDropdown: require('/src/components/filter/SearchWithDropdown.vue').default,FilterSort: require('/src/components/filter/Sort.vue').default,ProductsSelectedBadges: require('/src/components/products/SelectedBadges.vue').default,CardsProductTelematics: require('/src/components/cards/ProductTelematics.vue').default,InfinityLoader: require('/src/components/InfinityLoader.vue').default,NotFoundBlock: require('/src/components/NotFoundBlock.vue').default,Compare: require('/src/components/Compare.vue').default})
