
import { productsListNew } from '@/queries/products.gql'
import { FORM_STATUSES } from '~/types/forms'
import {
  formatProductCards,
  formatSuggestedProducts,
  handleSortTags,
  getUrlParamsByNames,
  formatFilters,
  getSelectedFilters,
} from '~/utils'
import { requestMixin } from '~/mixins'
const LOAD_AMOUNT = 6
const DEFAULT_SORT = 'default'
const ORDER_BY_SORT_TAG = 'ASC'
export default {
  name: 'ProductsTelematics',
  mixins: [requestMixin],
  navigation: {
    disableTransparency: true,
  },
  async asyncData({ store, i18n, route, $config, $sentry, app }) {
    try {
      const { popularProducts = [], sortTags = [] } = store.state.page?.content
      const loadSortTags = sortTags.length
      const page = Number(route.query?.page) || 1
      const searchParam = route.query?.search || ''
      const sortParam = route.query?.sort || ''
      const pageId = route.meta[0].page_id

      const { filter_id: filterId, ...filtersInitial } =
        store.state.page?.content?.filter
      const selectedFilters = getUrlParamsByNames(
        route.query,
        Object.keys(filtersInitial),
      )
      const filters = formatFilters(filtersInitial, selectedFilters)

      let sortTagsList = []
      let selectedSortTag = {}
      if (loadSortTags) {
        const result = handleSortTags(
          {
            code: DEFAULT_SORT,
            title: i18n.t('front.option.default'),
          },
          sortTags,
          sortParam,
        )
        sortTagsList = result.sortTagsList
        selectedSortTag = result.selectedSortTag
      }
      const variables = {
        first: page * LOAD_AMOUNT,
        page: 1,
        domainId: $config.domainId,
        languageCode: i18n.locale,
        pageId,
        excludeTags: $config.tagsToExclude,
        searchByTitle: searchParam,
        sortTag: sortParam,
        orderBySortTag: ORDER_BY_SORT_TAG,
      }
      if (Object.keys(selectedFilters).length > 0)
        variables.filter = {
          filter_id: filterId,
          ...selectedFilters,
        }
      const {
        data: { productsList },
      } = await app.$gqlQuery({
        query: productsListNew,
        fetchPolicy: 'no-cache',
        variables,
      })

      return {
        filterId,
        loadFilter: filters && filters.length > 0,
        loadSortTags,
        filters,
        page,
        searchString: searchParam,
        selectedSortTag,
        showMoreEnabled: productsList.paginatorInfo?.hasMorePages || false,
        totalProducts: productsList.paginatorInfo.total,
        fetchedProducts: formatProductCards(productsList.data, true),
        sortTags: sortTagsList,
        pageId,
        formStatus: FORM_STATUSES.SUCCESS,
        popularProducts,
      }
    } catch (ex) {
      $sentry.captureException(ex)
    }
  },
  data() {
    return {
      loadFilter: false,
      isFilterOpen: false,
      filters: [],
      filterId: 0,
      fetchedProducts: [],
      showMoreEnabled: true,
      page: 1,
      loading: false,
      searchString: '',
      loadSortTags: false,
      selectedSortTag: {},
      sortTags: [],
      totalProducts: 0,
      popularProducts: [],
      bannerBackgroundImage: {},
      isSliderVisible: true,
      isBannerVisible: false,
    }
  },
  computed: {
    formattedPopularProducts() {
      return this.popularProducts.map((product) => product.title)
    },
    isFiltered() {
      return (
        this.searchString.length > 0 ||
        (this.loadSortTags && this.selectedSortTag?.code !== DEFAULT_SORT) ||
        Object.keys(this.selectedFilters).length > 0
      )
    },
    suggestedProducts() {
      return formatSuggestedProducts(this.fetchedProducts)
    },
    selectedFilters() {
      return getSelectedFilters(this.filters)
    },
    selectedFilterQueryVariable() {
      if (Object.keys(this.selectedFilters).length > 0)
        return {
          filter: {
            filter_id: this.filterId,
            ...this.selectedFilters,
          },
        }
      return {}
    },
    searchSuggestionsQueryVariables() {
      return { ...this.selectedFilterQueryVariable, pageId: this.pageId }
    },
  },
  watch: {
    async searchString() {
      this.resetPagination()
      await this.fetchProducts()
    },
    async selectedSortTag() {
      this.resetPagination()
      await this.fetchProducts()
    },
    filters: {
      async handler() {
        this.resetPagination()
        await this.fetchProducts()
      },
      deep: true,
    },
  },
  created() {
    this.isBannerVisible =
      !this.isFiltered && this.$t(`front.${this.pageId}.show_banner`) === 'true'

    this.bannerBackgroundImage =
      this.$store?.state?.page?.content?.files['front.banner.image' || {}]

    this.isSliderVisible =
      !this.$te(`front.${this.pageId}.show_carousel`) ||
      this.$t(`front.${this.pageId}.show_carousel`) === 'true'
  },
  mounted() {
    this.$nextTick(() => {
      this.isFilterOpen =
        this.loadFilter && !this.$store.getters['resize/isMobile']
    })
  },
  methods: {
    setQueryParams() {
      const params = {
        ...(this.page && { page: this.page }),
        ...(this.selectedSortTag.code && { sort: this.selectedSortTag.code }),
        ...(this.searchString && { search: this.searchString }),
        ...this.selectedFilters,
      }
      this.$router.replace({ query: { ...params } }).catch(() => {})
    },
    onSearchSelect(value) {
      this.searchString = value
    },
    async fetchProducts(fromPagination = false) {
      if (!this.showMoreEnabled || this.loading) return false

      this.loading = true
      this.formStatus = this.formStatuses.LOADING
      if (fromPagination) this.page++
      try {
        const variables = {
          first: LOAD_AMOUNT,
          page: this.page,
          domainId: this.$config.domainId,
          languageCode: this.$i18n.locale,
          pageId: this.pageId,
          excludeTags: this.$config.tagsToExclude,
          searchByTitle: this.searchString,
          sortTag: this.selectedSortTag.code,
          orderBySortTag: ORDER_BY_SORT_TAG,
        }
        Object.assign(variables, this.selectedFilterQueryVariable)

        const {
          data: { productsList },
        } = await this.$gqlQuery({
          query: productsListNew,
          fetchPolicy: 'no-cache',
          variables,
        })

        this.showMoreEnabled = productsList?.paginatorInfo?.hasMorePages
        this.totalProducts = productsList?.paginatorInfo?.total
        this.fetchedProducts = [
          ...this.fetchedProducts,
          ...formatProductCards(productsList.data, true),
        ]
        this.loading = false
        this.formStatus = this.formStatuses.SUCCESS
        this.setQueryParams()
      } catch (e) {
        this.$sentry.captureException(e)
        this.formStatus = this.formStatuses.ERROR
        this.loading = false
      }
    },
    resetPagination() {
      this.showMoreEnabled = true
      this.page = 1
      this.loading = false
      this.fetchedProducts = []
    },
  },
}
